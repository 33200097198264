import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="About Us" />
    <h1>About</h1>
    <Container className="py-5">
        <h3 className='text-center my-5'>What Our Customers Say</h3>
        <Row className="mw-100 justify-content-around mx-0">
          <Col lg={4}>
            <Card className="shadow p-4 mt-3 mb-5 bg-light bg-gradient">
              <Card.Body>
                <blockquote className="blockquote">
                  <p className="">A well-known quote, contained in a blockquote element.</p>
                  <footer className="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="shadow p-4 mt-3 mb-5 bg-light bg-gradient">
              <Card.Body>
                <blockquote className="blockquote">
                  <p className="">A well-known quote, contained in a blockquote element.</p>
                  <footer className="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="shadow p-4 mt-3 mb-5 bg-light bg-gradient">
              <Card.Body>
                <blockquote className="blockquote">
                  <p className="">A well-known quote, contained in a blockquote element.</p>
                  <footer className="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
  </Layout>
)

export default IndexPage